
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import KTAssignDesignationDrawer from '@/layout/header/partials/assignDesignation/AssignDesingnationDrawer.vue';

export default defineComponent({
  name: 'add-trainer',
  components: {
    Form,
    Field,
    ErrorMessage,
    KTAssignDesignationDrawer,
  },
  data() {
    return {
      entityType: [],
      tranche: [],
      loading: false,
      load: false,
      entityTypeList: [],
      entityRole: [],
      createTrainer: false,
      batch: {
        assign_date: '',
        employee_id: '',
        entity_id: '',
        tranche_id: '',
        entity_type_id: '',
        entity_type_role: '',
        entity_type: '',
        role_name: '',
        institute_id: '',
        role_title: '',
        level: '',
      },
      componentKey: 0,
      text: '',
      entityTyperoleList: {},
      entityInfos: [],
      institutes: [],
      empDistricts: [],
      nidData: {},
      instituteList: [],
      employeeList: [],
      employeroleStatus: [],
      showDesignation: false,
    };
  },
  async created() {
    this.emitter.on('type-updated', async () => {
      // await this.checkRoleStatus();
    });
    this.emitter.on('list-update', async () => {
      await this.checkRoleStatus();
    });
    await this.getTranche();
    await this.getEntity();
    //await this.getEmployeeList();
    // await this.getEntityList();
  },
  methods: {
    async assignDrawer(data) {
      //console.log(batch);
      data.entity_id = this.batch.entity_id;
      data.institute_info_id = this.batch.institute_id;
      data.tranche_id = this.batch.tranche_id;
      this.emitter.emit('assign_designation_drawer', data);
    },
    async formSubmit() {
      await ApiService.post('employee/assign', this.batch)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.status == 200 && response?.data?.status == 'success') {
            Swal.fire({
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.batch = {
                assign_date: '',
                employee_id: '',
                entity_id: '',
                tranche_id: '',
                entity_type_id: '',
                entity_type_role: '',
                entity_type: '',
                role_name: '',
                institute_id: '',
                role_title: '',
                level: '',
              };
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: response.data.errors,
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html:
              response?.data?.error ||
              'Unknown error occured. Please check all the required field',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },

    async getTranche() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranche = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async ReleaseEmployee(data) {
      this.batch.employee_id = data.employee_id;
      this.batch.entity_type_role = data.entitytyperole.id;
      Swal.fire({
        title: 'Are you sure you want to Release?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, release!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.post('employee/release', this.batch)
            .then((response) => {
              console.log(response);
              this.checkRoleStatus();
              this.componentKey += 1;
              Swal.fire('Released!', response.data.message, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },

    async getEntity() {
      await ApiService.get('configurations/entity_type/list')
        .then((response) => {
          this.entityType = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEmployeeList() {
      await ApiService.get(
        'employee/list?entity_id=' +
          this.batch.entity_id +
          '&training_institute_id=' +
          this.batch.institute_id +
          '&tranche_id=' +
          this.batch.tranche_id
      )
        .then((response) => {
          this.employeeList = response.data.data.entityorganogram;
          // console.log(
          //   response.data.data.entityorganogram[0].entitytyperole.role_name
          // );
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityList() {
      console.log(this.batch.entity_type_id);
      await ApiService.get('entity/list?entity_type=' + this.batch.entity_type)
        .then((response) => {
          this.entityTypeList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
      await ApiService.get(
        'entity_type_role/role/allroles?entity_type_id=' +
          this.batch.entity_type
      )
        .then((response) => {
          this.entityRole = response.data.data.role;
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getEntityRole() {
      await ApiService.get('entity_type_role/role/allroles')
        .then((response) => {
          this.entityRole = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTrainningInstitute() {
      await ApiService.get('institute/list?entity_id=' + this.batch.entity_id)
        .then((response) => {
          //console.log(response.data.data);
          this.instituteList = response.data.data;
          this.getEmployeeList();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async checkRoleStatus() {
      this.load = true;
      await ApiService.get(
        'employee/organogram?entity_id=' +
          this.batch.entity_id +
          '&tranche_id=' +
          this.batch.tranche_id +
          '&training_institute_id=' +
          this.batch.institute_id +
          '&employee_id=' +
          this.batch.employee_id
      )
        .then((response) => {
          this.load = false;
          this.showDesignation = true;
          this.employeroleStatus = response.data.data.entityorganogram;
          //console.log(this.employeroleStatus);
          this.componentKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const RoleSchema = Yup.object().shape({
      //presentDistrict: Yup.string().required().label("Present District"),
      role_name: Yup.string().required().label('Role Name'),
      role_title: Yup.string().required().label('Role Title'),
      // nid:Yup.number().test('len', 'Must be exactly 5 characters', (nid) => { if(nid) return nid.toString().length === 8; }),
    });
    return {
      RoleSchema,
    };
  },
});
