
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

export default defineComponent({
  name: "kt-drawer-addcategory",
  components: { Field },

  data() {
    return {
      batch: {
        entity_type: "",
        entity_type_role: "",
        entity_id: "",
        training_institute_id: "",
        tranche_id: "",
        employee_id: "",
        assign_date: "",
      },
      componentKey:0,
      checkedDesignation: [],
      load: false,
      entityRole: [],
      category_type: "",

      loading: false,
    };
  },
  async created() {
    
    this.emitter.on("assign_designation_drawer", async (id) => {
      console.log(id);
      this.batch.entity_type = id.entitytyperole.entity_type_id;
      this.batch.entity_type_role = id.entitytyperole.id;
      this.batch.entity_id = id.entity_id;
      this.batch.tranche_id = id.tranche_id;
      this.batch.training_institute_id = id.institute_info_id;
      this.batch.employee_id = id.employee_id;
      await this.getEntityRole();
    });
  },
  methods: {
    async formSubmit() {
      let data = new FormData();
      for (var key in this.batch) {
        data.set(key, this.batch[key]);
      }
      //let checkedDesignation=JSON.stringify(Object.assign({}, this.checkedDesignation));
      data.set("designation", JSON.stringify(this.checkedDesignation));
      ApiService.post("employee/assign", data)
        .then((response) => {
          
          if (response.status == 200 && response?.data?.status == "success") {
            this.emitter.emit("list-update",true);
            Swal.fire({
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.batch = {
                entity_type: "",
                entity_type_role: "",
                entity_id: "",
                training_institute_id: "",
                tranche_id: "",
                employee_id: "",
                assign_date: "",
              };
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: response.data.errors,
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch((response) => {});
    },
    async getEntityRole() {
      await ApiService.get(
        "entity_type_role/role/allroles?entity_type_id=" +
          this.batch.entity_type
      )
        .then((response) => {
          console.log(response);
          this.entityRole = response.data.data.role;
        })
        .catch((response) => {
          console.log(response);
        });
    },
  },
});
